import { AiFetchEnum, KaiChatHistory } from 'src/Util/InterfaceAndTypeUtil';
import { fetch, fetchSpaces, getEnv } from 'w3-user-ui-component';

const getKaiUrl = () => {
  switch (getEnv()) {
    case 'develop':
      return 'api.kai.w3sdevelop.com';
    case 'staging':
      return 'api.kai.w3stages.com';
    default:
      return 'api.kai.w3spaces.com';
  }
};

type Method = 'get' | 'GET' | 'post' | 'POST';

const kAiApiFetch = async (method: Method, path: string, body?: any) => {
  const fullUrl = `https://${getKaiUrl()}/${path}`;

  return fetch({
    url: fullUrl,
    method: method,
    data: body,
  });
};

export const checkKaiTokenBalance = () => {
  return fetchSpaces<any>({
    url: '/userinfo',
    method: 'POST',
    data: {
      action: 'getUserKaiTokens',
    },
  });
};

const fetchAIChat = (question: string, history?: KaiChatHistory[]) => {
  return kAiApiFetch('POST', 'chat-api-v2/chat', {
    prompt: question,
    history: history,
  });
};

const fetchAIExplain = (code: string, history?: KaiChatHistory[]) => {
  const config: any = { prompt: code };
  if (history) {
    config['history'] = history;
  }

  return kAiApiFetch('POST', 'explain-api/explain', config);
};

/* const fetchAISnippets = (code: string, language: string) => {
  return kAiApiFetch('POST', 'snippets-api/snippet', {
    prompt: code,
    language,
  });
}; */

const fetchAIErrors = (code: string, language: string, history?: KaiChatHistory[]) => {
  const config: any = { prompt: code, language: language };
  if (history) {
    config['history'] = history;
  }

  return kAiApiFetch('POST', 'errors-api/fix-errors', config);
};

const fetchAIAutoComplete = (code: string, history?: KaiChatHistory[]) => {
  const config: any = { prompt: code };
  if (history) {
    config['history'] = history;
  }

  return kAiApiFetch('POST', 'autocomplete-api/autocomplete', config);
};
const fetchAIAutoTest = (code: string, history?: KaiChatHistory[]) => {
  const config: any = { prompt: code };
  if (history) {
    config['history'] = history;
  }

  return kAiApiFetch('POST', 'autotest-api/autotest', config);
};
const fetchAIRefactor = (code: string, history?: KaiChatHistory[]) => {
  const config: any = { prompt: code };
  if (history) {
    config['history'] = history;
  }

  return kAiApiFetch('POST', 'refactor-api/refactor', config);
};

export const fetchKaiPrices = () => {
  return kAiApiFetch('GET', 'prices-api/prices');
};

export const fetchAIResponse = (input: string, aiFetchType: AiFetchEnum, language?: string, history?: KaiChatHistory[]) => {
  switch (aiFetchType) {
    case AiFetchEnum.AUTOCOMPLETE:
      return fetchAIAutoComplete(input, history);
    case AiFetchEnum.AUTOTEST:
      return fetchAIAutoTest(input, history);
    case AiFetchEnum.CHAT:
      return fetchAIChat(input, history);
    case AiFetchEnum.ERROR:
      return fetchAIErrors(input, language!, history);
    case AiFetchEnum.EXPLAIN:
      return fetchAIExplain(input, history);
    case AiFetchEnum.REFACTOR:
      return fetchAIRefactor(input, history);
    /* case AiFetchEnum.SNIPPETS:
            return fetchAISnippets(input, language!); */
    default: // default to chat
      return fetchAIChat(input, history);
  }
};
