import { Dispatch } from 'redux';
import { fetchBilling, fetchSpacesPlugin, logging } from 'w3-user-ui-component';
import {
    setDomainsOrdersAction, setDomainProductsAction
} from '../Reducer/DomainsReducer';
import { DomainOrderType, DomainProductType } from '../../Util/InterfaceAndTypeUtil';

export function fetchDomainOrders() {
    return async (dispatch: Dispatch) => {
        const reqRes = await fetchSpacesPlugin<any>({
            url: '/domains/get-user-domains',
            method: 'POST',
        });

        logging.logDebug('DomainsAction -> fetchDomainOrders -> reqRes: ', reqRes);

        if (reqRes.error.code === '0') {
            const domainOrders = reqRes.data.domainNames.filter((domainOrder: DomainOrderType) => domainOrder.status.PAYMENT_REGISTERED);
            logging.logDebug('DomainsAction -> fetchDomainOrders -> domainOrders: ', domainOrders);

            dispatch(setDomainsOrdersAction(domainOrders));
        } else {
            logging.logError('DomainsAction -> fetchDomainOrders -> err: ', reqRes.error);
        }
    }
}

export function fetchDomainProducts() {
    return async (dispatch: Dispatch) => {
        const reqRes = await fetchBilling<any>({
            url: '/v2/tlds',
            method: 'GET',
        });

        logging.logDebug('DomainsAction -> fetchDomainProducts -> reqRes: ', reqRes);

        if (reqRes.error.code === '0') {
            const domainProducts = Object.assign({}, ...reqRes.data.map((domainProduct: DomainProductType) => ({ [domainProduct.name]: domainProduct })));

            logging.logDebug('DomainsAction -> fetchDomainProducts -> domainProducts: ', domainProducts);

            dispatch(setDomainProductsAction(domainProducts));
        } else {
            logging.logError('DomainsAction -> fetchDomainProducts -> err: ', reqRes.error);
        }
    }
}