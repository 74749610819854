import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { fetchFiles, setDisplayFooter, setDisplayHeader, setEditorContent, setSpaceContentState, setSpacePath } from 'src/Redux/Reducer/MainReducer';
import { FileType, SpaceStateType, SpaceTabType, SpaceType, SpaceVariantType } from 'src/Util/InterfaceAndTypeUtil';
import { getSpaceTechnology, isDynamicSpace } from 'src/Util/SpaceUtil';
import { addNotification, dismissNotification } from 'src/Redux/Reducer/NotificationReducer';
import { RootStateType } from 'src/Redux';
import { uuidv4 } from 'src/Util/MixedUtil';
import ComponentLoader from '../ComponentLoader/ComponentLoader';
import MobileEditorPanel from './MobileEditorPanel/MobileEditorPanel';
import useIsMobileView from 'src/hooks/UseIsMobileView';
import TopHeaderGroup from './TopHeaderGroup/TopHeaderGroup';
import Tooltip from '../Tooltip/Tooltip';
import BackArrowButton from '../Buttons/BackArrowButton';
import VariantHeader, { VariantSize } from '../VariantHeader/VariantHeader';

import styles from './CodeEditorWrapper.module.scss';
import stylesEditor from './CodeEditorPage.module.scss';
import { ButtonV2 } from 'w3-user-ui-component';
import { CloudUpload, PlayCircleFill } from 'react-bootstrap-icons';

const CodeEditorPage = React.lazy(() => import('./CodeEditorPage'));

const CodeEditorWrapper = () => {
  const main_state = useSelector((state: RootStateType) => state.main);
  const spaceContentState = useSelector((state: RootStateType) => state.main.space_content_state);
  const spaces = useSelector((state: RootStateType) => state.main.spaces);
  const spacesData = useSelector((state: RootStateType) => state.main.editor_content.spacesData);

  const fetchedFiles = useRef(false);

  const { spaceId } = useParams<any>();
  const history = useHistory();

  const displayMobileView = useIsMobileView();

  const dispatch = useDispatch();

  const [displayEditor, setDisplayEditor] = useState(false);
  const [space, setSpace] = useState<SpaceType>();

  const spaceState = useMemo<SpaceStateType>(() => {
    return space?.state || 'OPEN';
  }, [space]);

  const isSpaceDynamic = useMemo<boolean>(() => {
    return isDynamicSpace(space);
  }, [space]);

  const spaceTechnology = useMemo<SpaceVariantType>(() => {
    return getSpaceTechnology(space);
  }, [space]);

  useEffect(() => {
    const mySpace = spaces.filter((item) => item.id === spaceId);
    if (mySpace && mySpace.length === 1) {
      if (mySpace[0].state === 'OPEN' || mySpace[0].state === 'PRIVATE') {
        setSpace(mySpace[0]);
      } else {
        history.push(`/`);
      }
    } else {
      history.push(`/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId, spaces]);

  useEffect(() => {
    if (!fetchedFiles.current && !spaceContentState.loadingFiles && space && !displayEditor) {
      // we are not already loading some files
      fetchedFiles.current = true;

      const notifId = uuidv4();
      const isSpaceDynamic = isDynamicSpace(space);

      dispatch(
        addNotification({
          id: notifId,
          dismissed: false,
          autohide: false,
          closeButton: false,
          type: 'spinner',
          header: 'Loading space...',
          body: 'Fetching file structure...',
        }),
      );

      dispatch(setSpaceContentState({ isSpaceAllSetUp: false, loadingFiles: true }));

      try {
        dispatch(
          fetchFiles(space.id, main_state, (reqRes: any) => {
            if (reqRes.error.code === '0' && reqRes.data && reqRes.data.files) {
              // found the file structure
              let myFile: { path: string };

              if (spacesData && spacesData[spaceId] && spacesData[spaceId].tabs.length > 0) {
                const spaceData: SpaceTabType = spacesData[spaceId];
                myFile = spaceData.tabs[spaceData.activeTab];
              } else {
                myFile = reqRes.data.files.find((file: FileType) => file.path.toLowerCase() === `${space.id}/readme.md`.toLowerCase());
              }

              if (myFile) {
                // found the README file
                dispatch(
                  setEditorContent({
                    file_path: myFile?.path!,
                    filesAreLoaded: true,
                    backFromEditor: false,
                  }),
                );
              } else {
                dispatch(
                  setEditorContent({
                    file_path: undefined,
                    filesAreLoaded: true,
                    backFromEditor: false,
                  }),
                );
              }

              if (isSpaceDynamic) {
                dispatch(setSpaceContentState({ loadingFiles: false }));
              } else {
                dispatch(setSpaceContentState({ isSpaceAllSetUp: true, loadingFiles: false }));
              }

              dispatch(dismissNotification({ id: notifId }));

              dispatch(setSpacePath(space.id + '/'));

              setDisplayEditor(true);
            } else {
              dispatch(setSpaceContentState({ loadingFiles: false }));
              dispatch(dismissNotification({ id: notifId }));

              if (reqRes.error.code === '0') {
                // this should never happen, but do a check to be sure. If error.code then fetchFiles throws a notification
                dispatch(
                  addNotification({
                    id: uuidv4(),
                    dismissed: false,
                    autohide: false,
                    closeButton: true,
                    type: 'error',
                    header: 'Error fetching file structure ',
                    body: 'Unexpected error. Did not get any file structure.',
                  }),
                );
              }

              setDisplayEditor(true);
            }
          }),
        );
      } catch (error) {
        dispatch(setSpaceContentState({ loadingFiles: false }));

        dispatch(dismissNotification({ id: notifId }));

        dispatch(
          addNotification({
            id: uuidv4(),
            dismissed: false,
            autohide: false,
            closeButton: true,
            type: 'error',
            header: 'Error fetching file structure ',
            body: 'Unexpected error. Did not get any file structure.',
          }),
        );
      }
    }
  }, [dispatch, displayEditor, main_state, space, spaceContentState.loadingFiles, spaceId, spacesData]);

  // code for mounting and unmounting
  useEffect(() => {
    function changeClasses(value: boolean) {
      let addClass, removeClass;

      if (value) {
        addClass = styles.container_fluid_override;
        removeClass = 'container-fluid';
      } else {
        removeClass = styles.container_fluid_override;
        addClass = 'container-fluid';
      }

      const el = document.querySelector('.' + removeClass);
      el?.classList.add(addClass);
      el?.classList.remove(removeClass);
    }

    changeClasses(true);
    document.body.classList.add('code-editor-modal-body-wrp'); // hack for quota errors that are displayed

    dispatch(setDisplayFooter(false));
    dispatch(setDisplayHeader(false));

    return () => {
      changeClasses(false);
      document.body.classList.remove('code-editor-modal-body-wrp'); // hack for quota errors that are displayed
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!main_state || !spaces || spaces.length === 0) {
    history.push(`/`);
  }

  return displayEditor ? (
    <Suspense fallback={<ComponentLoader />}>
      <CodeEditorPage />
    </Suspense>
  ) : (
    <div className={'code_editor_page ' + styles.code_editor_page}>
      <TopHeaderGroup className={styles.top_header} classNameRight={stylesEditor.right_top_header}>
        {{
          left: (
            <>
              <Tooltip text='Back' placement='bottom-start' disabled={spaceContentState.loadingFiles}>
                <BackArrowButton
                  disabled={spaceContentState.loadingFiles}
                  text=''
                  handleOnClick={() => history.push(`/`)}
                  className={stylesEditor.back_button}
                />
              </Tooltip>

              <VariantHeader
                className={stylesEditor.tech_icon}
                title={spaceId}
                showIcon
                showDot
                spaceState={spaceState}
                iconType={spaceTechnology}
                size={VariantSize.Medium}
              />

              <div className={stylesEditor.action_wrapper}>
                <ButtonV2
                  className={stylesEditor.action_btns + ' ' + stylesEditor.buttons_preview_editor}
                  disabled={true}
                  onClick={() => {}}
                  text='Preview'
                  displayExternalIcon
                  positionExternalIcon='left'
                  ariaLabel='Preview'
                />

                <ButtonV2
                  text='Save'
                  loading={false}
                  icon={<CloudUpload />}
                  className={`${stylesEditor.action_btns} ${stylesEditor.save_btn}`}
                  disabled={true}
                  onClick={() => {}}
                  ariaLabel='Save'
                />

                {!isSpaceDynamic && (
                  <Tooltip text='Save and run code in preview.' placement='bottom-start'>
                    <ButtonV2
                      text='Run'
                      icon={<PlayCircleFill />}
                      className={`${stylesEditor.action_btns} ${stylesEditor.run_btn}`}
                      disabled={true}
                      onClick={() => {}}
                      ariaLabel='Run'
                    />
                  </Tooltip>
                )}
              </div>
            </>
          ),
          center: <div></div>,
          right: <div></div>,
        }}
      </TopHeaderGroup>

      {displayMobileView && (
        <MobileEditorPanel
          disabled={true}
          isDynamicSpace={isSpaceDynamic}
          activeItem={undefined}
          terminalIsConnected={false}
          logsIsConnected={false}
          onClickItem={() => {}}
        />
      )}
    </div>
  );
};

export default CodeEditorWrapper;
